import { predefinedFields } from 'components/manage/Metadata'
import { classNames } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

export const getCustomFields = (nfd: NfdRecord) => {
  return Object.keys(nfd.properties?.userDefined ?? {}).filter(
    (key) => !predefinedFields.includes(key) && key.match(/^ca\.[a-z]{1,5}$/) === null
  )
}

export const hasFields = (nfd: NfdRecord, fields: string[]) => {
  return fields.some((field) => nfd.properties?.userDefined?.[field])
}

export const getAboutClassName = (nfd: NfdRecord) => {
  const { unverifiedCaAlgo = [], unverifiedCa = {} } = nfd

  const unverifiedCrypto = Object.keys(unverifiedCa).reduce((acc, key) => {
    return [...acc, ...(unverifiedCa[key] || [])]
  }, [] as string[])

  const cardsMap = {
    personalInfo: hasFields(nfd, ['email', 'address']),
    socialMedia: hasFields(nfd, ['blueskydid', 'twitter', 'discord', 'telegram', 'linkedin']),
    websites: hasFields(nfd, ['domain', 'github', 'website']),
    algoXyz: hasFields(nfd, ['url']),
    customFields: getCustomFields(nfd).length > 0,
    unverifiedAddr: [...unverifiedCaAlgo, ...unverifiedCrypto].length > 0,
    verifiedAddr: true,
    details: true
  }

  const cardsCount = Object.values(cardsMap).filter(Boolean).length

  return classNames(
    'mx-auto space-y-4 sm:space-y-6 columns-1 xl:columns-2 xl:gap-6 5xl:space-y-8',
    cardsCount > 2 ? '4xl:columns-3 5xl:gap-8' : 'mx-auto max-w-[1600px]'
  )
}
